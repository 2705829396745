import React from 'react';
import './home.scss';
import Select from "react-select";

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>О мониторинге</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{fontSize:'14px'}}>
        
        Автоматизированный мониторинг организации горячего питания обучающихся включает:
        <ul>
<li>средства наблюдения за меню фактического питания, опубликованных на официальных сайтах образовательных организаций;</li> 
<li>анализ меню с выявлением отклонений данных о потреблении (выход блюд, калорийность и основные пищевые вещества по приемам пищи) от нормативов потребления;</li>
<li>мониторинг стоимости комплексов блюд по приемам пищи;</li>
<li>состава меню фактического питания;</li>
<li>исполнительской дисциплиной по публикации меню на официальных сайтах.</li> 
        </ul>
Вышеперечисленные
параметры контролируются с использованием централизованной базы данных, аналитических
отчетов для специалистов ФЦМПО и региональных органов управления образованием, а также
публичного интерфейса анализа меню на специализированном интернет-сайте
https://мониторингпитание.рф. В развитие находящейся в эксплуатации системы мониторинга по
вышеперечисленным параметрам в систему внесены:
<ul>
<li>средства для построения рейтинга</li>
<li>общественного контроля;</li>
<li>визуализации результатов массовых социологических опросов по питанию;</li>
<li>средства взаимодействия со сторонними информационными ресурсами в части справочника пищеблоков и школ</li>
<li>сформулированы общие требования к проектируемым средствам
визуализации и специализированным рабочим местам специалистов региональных управлений
образования и экспертов ФЦМПО.</li>
</ul>
Примечание: иные вопросы, относящиеся к управлению системой школьного питания (т. к.
мониторинг формы организации питания в школе и соответствующих систем ценообразования;
численность питающихся; фактический товарооборот продуктов и его сопоставление со
среднесуточными нормативами по продуктовым группам; контроль питания не только младшей
возрастной категории; обращения граждан и результаты их рассмотрения и др.) выходят за рамки
существующей системы мониторинга питания обучающихся и могут быть предметом технического
задания для последующих стадий (версий) системы мониторинга.
Задача мониторинга питания обучающийся начальных классов РФ.
В части мониторинга меню фактического питания, публикуемых на сайтах школ, система включает:
<ul>
<li> регулярное считывание файлов меню с установленным шаблоном имени файла
«гггг-мм-дд-sm.xlsx» в центральную базу данных;</li>
<li> контроль считываемых файлов на соответствие формату бланка;</li>
<li> разбор скачанных файлов с помещение полученного в цифровом виде
документа в информационной базе (вида «Меню фактическое»)</li>
</ul>
Данные работы выполняются в центральной информационной базе ФЦМПО (наплатформе
«1С:Предприятие»), размещенной на интернет-сайте <a href='https://cemon.ru'>https://cemon.ru</a>, в
автоматическом режиме, один раз в сутки.
        </div>
      </div>
    </React.Fragment>
)}
