import React from 'react';
import Select from "react-select";
import Scheduler, { Resource } from 'devextreme-react/scheduler';

export default function Upload() {
    //Руссификация сообщений

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Отчёт</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style={{fontSize:'14px'}}>
        </div>
      </div>
    </React.Fragment>
)}
